import React, { useState, useEffect, useCallback, useMemo } from 'react'; // Ensure useMemo is imported
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Default styles
import './Calendar.css'; // Your custom styles

const MyCalendar = () => {
  const [date, setDate] = useState(new Date()); // To store the current date
  const [nextShowDate, setNextShowDate] = useState(null); // To store the next show date
  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  // Define the holiday dates
  const holidays = useMemo(() => [
    new Date(2024, 11, 25), // Example: Christmas (Dec 25, 2024)
    new Date(2025, 0, 1), // Example: New Year's Day (Jan 1, 2025)
    // Add more holidays here
  ], []);

  // Check if a date is a holiday
  const isHoliday = useCallback((date) => {
    return holidays.some(holiday => 
      holiday.getDate() === date.getDate() &&
      holiday.getMonth() === date.getMonth() &&
      holiday.getFullYear() === date.getFullYear()
    );
  }, [holidays]);

  // Check if a date is the second or fourth Sunday
  const isSecondOrFourthSunday = useCallback((date) => {
    const dayOfWeek = date.getDay();
    const dayOfMonth = date.getDate();

    // Check if it's Sunday (dayOfWeek === 0)
    if (dayOfWeek === 0) {
      // Check if it's the second or fourth Sunday
      const weekOfMonth = Math.ceil(dayOfMonth / 7); // Get week number of the month
      return weekOfMonth === 2 || weekOfMonth === 4;
    }
    return false;
  }, []);

  // Check if a date is a weekend (Saturday or Sunday)
  const isWeekend = (date) => {
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6; // 0 for Sunday, 6 for Saturday
  };

  // useEffect to set the next show date when the component mounts
  useEffect(() => {
    // Function to calculate the next show date
    const calculateNextShowDate = () => {
      let currentDate = new Date();
      while (true) {
        if (isSecondOrFourthSunday(currentDate) && !isHoliday(currentDate)) {
          return currentDate;
        }
        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      }
    };

    const showDate = calculateNextShowDate();
    setNextShowDate(showDate);
  }, [isHoliday, isSecondOrFourthSunday]); // Add functions to dependencies

  return (
    <div>
      <Calendar
        className="calendar"
        onChange={setDate}
        value={date}
        tileClassName={({ date, view }) => {
          if (view === 'month') {
            if (isSecondOrFourthSunday(date) && !isHoliday(date)) {
              return 'highlight'; // Highlight second and fourth Sundays if not holidays
            } else if (isHoliday(date)) {
              return 'holiday'; // Apply holiday class if it's a holiday
            } else if (isWeekend(date)) {
              return 'weekend'; // Change color for weekend dates
            } else {
              return 'day'; // Default
            }
          }
          return null; // Default
        }}
      />
      {nextShowDate && (
        <h3>Our Next Show is: {nextShowDate.toLocaleDateString('en-US', options)}</h3>
      )}
    </div>
  );
};

export default MyCalendar;
