import React from 'react';
import './GoogleMap.css';

const GoogleMap = () => {
  return (
    <div className='map'>
      <iframe title = "Google Map"
        style={{ width: '100%', height: '100%' }}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3021.7312763285336!2d-73.42480042373457!3d40.76793477138529!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e82a4b8baccfc7%3A0x655200e0207fbcce!2s535%20Broadhollow%20Rd%2C%20Melville%2C%20NY%2011747!5e0!3m2!1sen!2sus!4v1727324282129!5m2!1sen!2sus" 
        loading="lazy" 
         />
    </div>
  );
};



export default GoogleMap;
