import React from 'react';
import './Header.css';  // Create a separate CSS file for styling

const Header = () => {
    return (
      <header className="sticky-header">
        <div className="header-content">      
          <img src={`${process.env.PUBLIC_URL}/images/MelvilleCoin.webp`} className="header-image" alt="Coin Logo" />
          <h1 className="show-title">Melville Coin, Stamp, & Collectibles Show</h1>
          <h1 className="contact">Contact Us: (516) 557-7096</h1>

        </div>
      </header>
    );
  };

export default Header;
