import React from "react";
import "./Location.css";

const Location = () => {
  return (
    <header className="location">
      <img 
        src={`${process.env.PUBLIC_URL}/images/EntranceDirections.webp`} 
        className="entrance-image" 
        alt="Venue" 
        height="100%" 
        width="100%" 
      />
    </header>
  );
};

export default Location;
