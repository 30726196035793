import React, { useEffect, useState } from 'react';
import './BackgroundSlider.css'; // Import your CSS file for styles

const images = [
  `${process.env.PUBLIC_URL}/images/coins.webp`,
  `${process.env.PUBLIC_URL}/images/welcomeBackground1.webp`,
  `${process.env.PUBLIC_URL}/images/stamps.webp`,
  `${process.env.PUBLIC_URL}/images/baseballcards.webp`,
  // Add more images as needed
];

const BackgroundSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false); // To control the fade effect

  useEffect(() => {
    // Preload images
    const preloadImages = () => {
      images.forEach((image) => {
        const img = new Image();
        img.src = image;
      });
    };

    preloadImages(); // Call the preload function

    const interval = setInterval(() => {
      setFade(true); // Start the fade out
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(false); // Fade in the new image
      }, 1000); // Match this with your CSS transition duration
    }, 4000); // Change image every 4 seconds (1 second for fade, 3 seconds for display)

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, [currentIndex]);

  return (
    <div
      className={`background-slider ${fade ? 'fade' : ''}`}
      style={{ backgroundImage: `url(${images[currentIndex]})` }}
    >
      <div className="welcome-content">
        <h1 className="second-text">The Melville Coin Show</h1>
        <h2 className="third-text">Every <strong>Second</strong> & <strong>Fourth Sunday</strong> since <strong>1964!</strong></h2>
        <h2 className="fourth-text">Free Admission!!</h2>
        <h2 className="fifth-text">Free Appraisals!!</h2>
      </div>
    </div>
  );
};

export default BackgroundSlider;
